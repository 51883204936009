import React from "react";
import Container from "../common/Container";

import advancementLogo from "../../images/Advancement.png";
import empowermentLogo from "../../images/Empowerment.png";
import creationLogo from "../../images/Creation.png";
import bettermentLogo from "../../images/Betterment.png";

const goals = [
  {
    logo: advancementLogo,
    description:
      "Technical excellence and leadership in AI-based decentralized systems.",
  },
  {
    logo: empowermentLogo,
    description:
      "Fostering a culture of collaboration, openness and partnerships.",
  },
  {
    logo: creationLogo,
    description:
      "Unlocking sustainable business models through the power of Web3 and AI.",
  },
  {
    logo: bettermentLogo,
    description:
      "Empowering data sovereignty in a rapidly evolving digital landscape.",
  },
];

const GolasSection = () => {
  return (
    <div className="mt-[50px] md:mt-[70px] bg-white py-[50px] md:py-[100px]">
      <Container classes="max-w-[1248px] !px-6">
        <h4 className="pt-8 text-5xl font-bold text-center textHeading ">
          <span className="textHeadingGradient"> Value&nbsp;</span>
          <span className="textHeadingNormal">goals</span>
        </h4>
        <div className="flex flex-col mt-2 md:mt-4 md:flex-row">
          <p className="mx-auto mt-4 text-center md:max-w-lg">
            We are driven by the aspiration to empower data sovereignty in the
            rapidly growing global digital landscape whilst maintaining our
            focus on unlocking novel and sustainable business models for the
            economy of the future. Coupled with the devotion to ensure technical
            excellence and leadership in AI-based decentralized systems whilst
            actively fostering a culture of innovation, openness and
            collaborative growth.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 mt-12 md:gap-6 md:grid-cols-4">
          {goals.map(({ logo, description }, index) => (
            <div key={index}>
              {/* <GoalsCard logo={logo} description={description} key={index} /> */}
              <img
                src={logo}
                alt="icon"
                className="max-w-[40px] md:max-w-[48px] mx-auto "
              />
              <p className="mx-auto mb-12 text-center">{description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default GolasSection;
