import React from "react";

import Layout from "../components/layout";
import HeroSection from "../components/home/HeroSection";

import VisionAndMissionSection from "../components/home/VissionAndMissionSection";
import GolasSection from "../components/home/GolasSection";
import MissionSection from "../components/home/MissionSection";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";
import SEO from "../components/common/Seo";
import NewsLaterModal from "../components/modal/NewsLaterModal";

function Index() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);

  return (
    <Layout headerClass="relative">
      <div ref={bannerRef}>
        <HeroSection />
      </div>
      <VisionAndMissionSection />
      <GolasSection />
      <MissionSection />
      <NewsLaterModal />
    </Layout>
  );
}

export default Index;

export const Head = () => <SEO title="Home" />;
