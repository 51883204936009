import React from "react";
const VisionAndMissionSection = () => {
  return (
    <div className="mx-auto px-[20px] lg:px-[70px] flex justify-center gap-[30px]   w-full container   md:-mt-20  mt-[7rem]  flex-col ">
      <div className="heroCards relative visionDesktop visionMobile  z-10  bg-white mx-auto rounded-[20px] flex flex-col lg:flex-row">
        <div className="p-4 xl:w-[50%] lg:w-[45%] w-[50%] h-[50%] lg:h-[0%] "></div>
        <div className="flex p-[40px] lg:p-[5px] flex-col justify-center z-10 w-full mt-[200px] lg:mt-[0px] lg:w-[50%] 2xl:py-[40px] xl:py-[20px]  md:py-[0px] 2xl:pl-[30px]  xl:max-w-[47%] lg:max-w-[50%]">
          <h4 className="font-Lexend lg:text-[36px] text-[25px] font-semibold leading-12 text-left tracking-normal mb-4">
            Vision
          </h4>
          <div className="mb-6 text-left break-words">
            <p className="lg:text-[16px] text-[14px] ">
              At the Fetch.ai Foundation, we envision a world where technology
              seamlessly integrates into every aspect of industry and
              enterprise, unlocking limitless potential for growth and
              innovation.
            </p>
            <br />
            <p className="lg:text-[16px] text-[14px]">
              Our aim is to revolutionize the way we do business by harnessing
              the collective power of cutting-edge AI, Web3, and multi-agent
              systems technology.
            </p>
            <br />
            <p className="lg:text-[16px] text-[14px]">
              We are dedicated to creating a transparent, robust, and adaptive
              ecosystem, one that can evolve and readjust to the ever-changing
              demands of the world. We strive to encourage the creation of
              robust and innovative business models by building a growing
              community of professionals from enterprises, development teams and
              researchers. Together, we will create a future where possibilities
              are endless and innovation is the norm.
            </p>
          </div>
        </div>
      </div>
      <div className="heroCards relative missionDesktop missionMobile  z-10  bg-white mx-auto rounded-[20px] flex flex-col lg:flex-row">
        <div className="flex p-[40px] lg:p-[30px] flex-col justify-center z-10 w-full mt-[200px] lg:mt-[0px] lg:w-[50%] 2xl:py-[40px] xl:py-[20px]  md:py-[0px] 2xl:pl-[30px]  xl:max-w-[47%] lg:max-w-[50%]">
          <h4 className="font-Lexend lg:text-[36px] text-[25px] font-semibold leading-12 text-left tracking-normal mb-4">
            Mission
          </h4>
          <div className="mb-6 text-left break-words">
            <p className="lg:text-[16px] text-[14px] ">
              Our mission is to drive the advancement of AI and Web3
              technologies, with a focus on creating a collaborative ecosystem
              for industry participants that is poised to unlock new innovations
              and business opportunities.
            </p>
            <br />
            <p className="lg:text-[16px] text-[14px]">
              As a transparent governing body, initiated by Fetch.ai and Bosch,
              we bring key industry participants together, advance research and
              adoption in Web3 technology, cryptography, and artificial
              intelligence, and advocate for the shared benefits of these
              technologies.
            </p>
            <br />
            <p className="lg:text-[16px] text-[14px]">
              As a coordinating entity, governed by representatives from both
              organizations, we will strive for operational excellence to ensure
              the success of our mission and to open new doors of opportunities
              for businesses and individuals alike.
            </p>
          </div>
          <div className="p-4 xl:w-[50%] lg:w-[45%] w-[50%] h-[50%] lg:h-[0%] "></div>
        </div>
      </div>
    </div>
  );
};

export default VisionAndMissionSection;
