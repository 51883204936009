import React from "react";
import classNames from "classnames";
import { useRef } from "react";
import "./GoalsCard.css";

const textArray = [
  "Access to sustainable business models and data-driven value streams.",
  "Building cutting-edge infrastructure and software solutions for cross-sectoral industrial applications.",
  "Access to a rapidly growing and transparent ecosystem of talented data scientists, distributed systems specialists and software engineers.",
  "Fostering data sovereignty and privacy in an open data economy.",
  "Development, integration and implementation support.",
  "Access to powerful toolkits and scalable business solutions",
];

const MissionSection = ({
  cardSectionClasses,
  classes,
  titleSectionClasses,
}) => {
  const socialCardsRef = useRef(null);

  return (
    <section
      className={classNames("px-6 py-[50px] container mx-auto md:py-[100px]", {
        [classes]: classes,
      })}
    >
      <div
        className={classNames({ [titleSectionClasses]: titleSectionClasses })}
      >
        <h2 className=" font-bold text-[46px]   leading-[48px] text-[#0D283E] text-center">
          Our{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
            {" "}
            mission
          </span>
        </h2>
      </div>
      <div
        className={classNames("mx-auto mt-[40px] md:mt-[80px]", {
          [cardSectionClasses]: cardSectionClasses,
        })}
      >
        <div
          ref={socialCardsRef}
          className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2   gap-[20px] justify-center"
        >
          {textArray.map((text, index) => (
            <div
              key={index}
              className="border-gradient text-center  px-[20px] pb-[30px] pt-[20px] "
            >
              <p className="text-[16.83px]">{text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
