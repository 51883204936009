import React from "react";
import { useNewsLaterContext } from "../../context/NewsLaterContext";
import PrimaryButton from "../atoms/PrimaryButton";

export default function HeroSection() {
  const { dispatch, actions } = useNewsLaterContext();
  return (
    <div className="container mx-auto lg:px-[70px] pb-[140px] pt-[30px] px-[30px]  ">
      <div className="w-full mx-auto lg:gap-0">
        <div className="lg:flex lg:items-center">
          <div className="md:pb-3 lg:-mr-4">
            <h1 className="text-[40px] leading-[48px] font-semibold text-[#0D283E] md:text-[56px] md:leading-[67px]  flex items-start md:items-center flex-col md:flex-row">
              <span className="inline-block ">Fetch.ai&nbsp;</span>
              <span className="text-[42px] md:text-[56px] bg-clip-text text-transparent  bg-gradient-to-r from-[#4789CD] via-[#28D4F5] to-[#59D99D] inline-block pt-1.5 md:pt-0">
                Foundation
              </span>
            </h1>
            <div className="max-w-[640px] text-[#0D283E]">
              <h2 className=" pt-4 pb-10  text-2xl text-[22px] hidden md:block">
                Building the autonomy of the digital economy
              </h2>
              <div className="pt-5 text-xs font-thin text-left break-words md:text-base md:pt-0 md:text-start">
                <p>
                  The Fetch.AI Foundation is a non-profit organization based in
                  the Netherlands, founded and governed by representatives from
                  Fetch.ai and Bosch. The foundation aims to advance innovative
                  technology in the areas of artificial intelligence and
                  distributed systems.
                </p>
                <br />
                <p>
                  The Foundation&apos;s main goals are to promote an open and
                  transparent ecosystem based on data sovereignty and balanced
                  governance, and to foster innovation and cooperation between
                  industry participants through collective research and
                  development, collaborative applications, shared initiatives,
                  and the discovery of valuable business models.
                </p>
              </div>
              <div className="pt-3 text-center md:text-left ">
                <PrimaryButton
                  classes="md:mr-[28px]  mx-auto mb-4 md:mb-0 w-full md:w-auto "
                  label="Register"
                  action={() =>
                    dispatch({
                      type: actions.SET_IS_MODAL_OPEN,
                      payload: true,
                    })
                  }
                />
                <a
                  href="https://fetch.ai/docs/concepts"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="font-medium rounded-[6px]  w-full md:w-auto border-[1px] border-solid border-[#3EA2DA] px-[26px] md:px-[32px] py-[16px] bg-transparent text-[15px] md:text-[16px] tracking-wider text-[#3274B8]">
                    Visit our learning hub
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
